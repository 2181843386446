<template>
  <admin>
    <metatag title="Quotations"></metatag>

    <page-header></page-header>

    <v-row>
      <v-col>
        <v-card>
          <toolbar-menu
            class="quotations-toolbar"
            ref="toolbar"
            :filter-model="true"
            :filter-status="true"
            :filter-action="true"
            :items.sync="tabletoolbar"
            @update:search="setSearch"
            @update:status="handleFilterStatus"
            @update:action="handleFilterAction"
            @update:model="handleFilterModel"
          ></toolbar-menu>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="resources.headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :loading="loading"
              :items-per-page="5"
              color="primary"
              item-key="id"
              class="text-no-wrap"
            >
              <template v-slot:progress><span></span></template>

              <template v-slot:loading>
                <v-slide-y-transition mode="out-in">
                  <skeleton-avatar-table></skeleton-avatar-table>
                </v-slide-y-transition>
              </template>

              <template v-slot:item.created_at="{ item }">
                <small>{{ formatDate(item.created_at) }}</small> <br />
              </template>

              <template v-slot:item.birthdate="{ item }">
                <small>{{ formatDate(item.birthdate, "D MMMM YYYY") }}</small> <br />
              </template>

              <template v-slot:item.name="{ item }">
                <small>{{ item.title }} {{ item.first_name }} {{ item.last_name }}</small> <br />
                <small>{{ item.mobile }}</small> <br />
                <small>{{ item.email }}</small>
              </template>

              <template v-slot:item.vehicle="{ item }">
                <small>{{ item.model_name }}</small> <br />
                <small>{{ item.variant ? item.variant.name : item.variant_name }}</small> <br />
                <small>{{ item.color ? item.color.name : item.color_name }}</small>
              </template>

              <template v-slot:item.sales_consultant="{ item }">
                <small>{{
                  item.assigned_sc_user
                    ? `${item.assigned_sc_user.first_name} ${item.assigned_sc_user.last_name}`
                    : ""
                }}</small>
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip label>
                  {{ item.status.label }}
                </v-chip>
              </template>

              <template v-slot:item.date_uploaded="{ item }">
                <small>
                  {{ item.uploaded_at != "0000-00-00 00:00:00" ? formatDate(item.uploaded_at) : "" }}
                </small>
              </template>

              <template v-slot:item.action="{ item }">
                <v-chip label>
                  {{ item.action.label }}
                </v-chip>
              </template>
              <template v-slot:item.id="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="previewItem(item)" text icon color="primary" v-on="on">
                      {{ item.id }}
                    </v-btn>
                  </template>
                  <span v-text="'Preview'"></span>
                </v-tooltip>
              </template>

              <template v-slot:item.editAction="{ item }">
                <div class="text-no-wrap">
                  <!-- Upload -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
                      <v-btn
                        text
                        icon
                        v-on="on"
                        v-on:click="handleUpload(item)"
                        :loading="loadingFileUpload"
                        :disabled="loadingFileUpload"
                      >
                        <v-icon small>mdi-upload</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Upload'"></span>
                  </v-tooltip>
                  <!-- Upload -->
                  <!-- Download -->
                  <v-tooltip bottom v-if="item.document">
                    <template v-slot:activator="{ on }">
                      <v-btn text icon v-on="on" @click="downloadQuotationDocument(item)">
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Download'"></span>
                  </v-tooltip>
                  <!-- Download -->
                  <!-- Edit -->
                  <can :code="['dealer', 'sales_consultant']">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="editItem(item)" text icon v-on="on">
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Edit'"></span>
                    </v-tooltip>
                  </can>
                  <!-- Edit -->
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <can :code="['dealer']">
      <v-dialog
        v-model="inquiryDialog"
        max-width="600px"
        class="overflow-hidden"
        persistent
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">Edit #{{ editedItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <SalesConsultant :assigned_sc_user_id="editedItem.sales_consultant" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    append-icon="mdi-chevron-down"
                    :items="statuses"
                    item-text="title"
                    item-value="slug"
                    label="Status"
                    outlined
                    clearable
                    hide-details
                    clear-icon="mdi-close-circle-outline"
                    background-color="selects"
                    v-model="editedItem.status"
                    :rules="[(value) => !!value || 'Status is required']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    append-icon="mdi-chevron-down"
                    :items="actions"
                    item-text="title"
                    item-value="slug"
                    label="Action"
                    outlined
                    clearable
                    hide-details
                    clear-icon="mdi-close-circle-outline"
                    background-color="selects"
                    v-model="editedItem.action"
                    :rules="[(value) => !!value || 'Action is required']"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              :loading="loadingSave"
              :disabled="loadingSave"
              @click="closeInquiry"
            >
              Cancel
            </v-btn>

            <v-btn
              large
              exact
              color="green darken-1"
              class="ma-1 white--text px-5"
              @click="validate"
              :loading="loadingSave"
              :disabled="loadingSave"
            >
              <v-icon left>mdi-content-save</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </can>

    <v-dialog v-model="previewDialog" persistent max-width="900px" class="overflow-hidden">
      <v-card class="pa-4">
        <v-card-title>
          <h2 title="Edit" class="mb-1"># {{ selectedItem.id }}</h2>
        </v-card-title>
        <v-card-text class="overflow-y-auto">
          <!-- Background Details -->
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ "Customer" }}</td>
                  <td>
                    {{ selectedItem.title }} {{ selectedItem.first_name }}
                    {{ selectedItem.last_name }}
                  </td>
                  <td class="font-weight-bold">{{ "Date of Birth" }}</td>
                  <td>{{ formatDate(selectedItem.birthdate, "D MMMM YYYY") }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Province" }}</td>
                  <td>{{ selectedItem.province }}</td>
                  <td class="font-weight-bold">{{ "Municipality" }}</td>
                  <td>{{ selectedItem.municipality }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Barangay" }}</td>
                  <td>{{ selectedItem.barangay }}</td>
                  <td class="font-weight-bold">{{ "Zip Code" }}</td>
                  <td>{{ selectedItem.zipcode }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Street" }}</td>
                  <td>{{ selectedItem.street }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Email" }}</td>
                  <td>{{ selectedItem.email }}</td>
                  <td class="font-weight-bold">{{ "Mobile" }}</td>
                  <td>{{ selectedItem.mobile }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Model" }}</td>
                  <td>{{ selectedItem.model_name }}</td>
                  <td class="font-weight-bold">{{ "Variant" }}</td>
                  <td>
                    {{
                      selectedItem.variant ? selectedItem.variant.name : selectedItem.variant_name
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Color" }}</td>
                  <td>
                    {{ selectedItem.color ? selectedItem.color.name : selectedItem.color_name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Inquiry Date" }}</td>
                  <td>{{ formatDate(selectedItem.created_at, "D MMMM YYYY @ h:mm a") }}</td>
                  <td class="font-weight-bold">{{ "Sales Consultant" }}</td>
                  <td>
                    {{
                      selectedItem.assigned_sc_user
                        ? `${selectedItem.assigned_sc_user.first_name} ${selectedItem.assigned_sc_user.last_name}`
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Status" }}</td>
                  <td>
                    <v-chip label>{{
                      selectedItem.status ? selectedItem.status.label : ""
                    }}</v-chip>
                  </td>
                  <td class="font-weight-bold">{{ "Action" }}</td>
                  <td>
                    <v-chip label>{{
                      selectedItem.action ? selectedItem.action.label : ""
                    }}</v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Assigned Date" }}</td>
                  <td>{{ formatDate(selectedItem.sc_assigned_at, "D MMMM YYYY @ h:mm a") }}</td>
                  <td class="font-weight-bold">{{ "Date Created" }}</td>
                  <td>{{ formatDate(selectedItem.created_at, "D MMMM YYYY @ h:mm a") }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Date Updated" }}</td>
                  <td>{{ formatDate(selectedItem.updated_at, "D MMMM YYYY @ h:mm a") }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Preferred Communication" }}
                  </td>
                  <td></td>
                  <td class="font-weight-bold">
                    {{ "Preferred Dealer" }}
                  </td>
                  <td>{{ selectedItem.dealer_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- Background Details -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="closePreview">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin>
</template>

<script>
import SalesConsultant from "@/components/SalesConsultant/SalesConsultant";
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      statusFilter: null,
      actionFilter: null,
      modelFilter: null,
      inquiryDialog: false,
      previewDialog: false,
      resources: {
        headers: [
          { text: "ID", align: "left", value: "id", class: "text-no-wrap" },
          { text: "Name", align: "left", value: "name", class: "text-no-wrap" },
          { text: "Vehicle", value: "vehicle", class: "text-no-wrap" },
          { text: "Sales Consultant", value: "sales_consultant", class: "text-no-wrap" },
          { text: "Preferred Dealer", value: "dealer_name", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap" },
          { text: "Action", value: "action", class: "text-no-wrap" },
          { text: "Date Created", value: "created_at", class: "text-no-wrap" },
          { text: "Date Uploaded", value: "date_uploaded", class: "text-no-wrap" },
          {
            text: "Actions",
            align: "center",
            value: "editAction",
            class: "muted--text text-no-wrap",
          },
        ],
        data: [],
      },
      editedItem: {
        id: null,
        sales_consultant: null,
        status: null,
        action: null,
      },
      tabletoolbar: {
        isSearching: false,
        search: null,
        type: "quotation",
      },
      loading: true,
      options: {},
      items: [],
      total: 0,
      selectedItem: {},
      valid: true,
      loadingSave: false,
      loadingFileUpload: false,
      selectedFile: {},
      selectedId: null,
    };
  },
  components: {
    SalesConsultant,
  },
  computed: {
    ...mapGetters({
      data: "quotations/GET_QUOTATIONS",
      actions: "quotations/GET_QUOTATION_ACTIONS",
      statuses: "quotations/GET_QUOTATION_STATUSES",
      model: "models/GET_MODEL",
      consultant: "report/GET_SALES_CONSULTANT",
    }),
  },
  async mounted() {
    await this.getQuotationStatuses();
    await this.getQuotationActions();
    await this.getUserType();
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getQuotations: "quotations/getQuotations",
      update: "quotations/updateQuotation",
      getQuotationStatuses: "quotations/getQuotationStatuses",
      getQuotationActions: "quotations/getQuotationActions",
      uploadDocument: "quotations/uploadQuotationDocument",
      download: "quotations/download",
      getUserType: "auth/getUserType",
      getModel: "models/getModel",
    }),
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (this.modelFilter) {
        data.model = this.modelFilter;
      }
      if (this.statusFilter) {
        data.status = this.statusFilter;
      }
      if (this.actionFilter) {
        data.action = this.actionFilter;
      }
      this.getQuotations(data).then((data) => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    formatDate(item, format) {
      return helpers.format_date(item, format);
    },

    previewItem(item) {
      this.previewDialog = true;
      this.selectedItem = item;
    },

    closePreview() {
      this.previewDialog = false;
      this.selectedItem = {};
    },

    showTip(e) {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },

    editItem(item) {
      this.editedItem.customer_id = item.customer_id;
      this.editedItem.id = item.id;
      this.editedItem.status = item.status.code;
      this.editedItem.action = item.action.code;
      this.editedItem.sales_consultant = (item.assigned_sc_user) ? item.assigned_sc_user.id : null;
      this.inquiryDialog = true;
    },

    closeInquiry() {
      this.inquiryDialog = false;
      this.editedItem = {
        customer_id: null,
        status: null,
        action: null,
        sales_consultant: null,
      };
    },

    async validate() {
      this.loadingSave = true;
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        this.loadingSave = false;
        return;
      }
      this.updateQuotation();
    },

    async handleFilterStatus(val) {
      this.statusFilter = val;
      await this.getItems(1, 5, "");
    },

    async handleFilterAction(val) {
      this.actionFilter = val;
      await this.getItems(1, 5, "");
    },

    async handleFilterModel(val) {
      this.modelFilter = "";
      if (val) {
        await this.getModel(val);
        this.modelFilter = this.model ? this.model.name : "";
      }
      await this.getItems(1, 5, "");
    },

    async updateQuotation() {
      const data = {
        status: this.editedItem.status,
        action: this.editedItem.action,
        assigned_sc_user_id: this.consultant,
      };
      await this.update({
        data,
        id: this.editedItem.id,
      }).then((data) => {
        this.loadingSave = false;
        this.$refs.form.reset();
        this.inquiryDialog = false;
      });

      this.loadingSave = false;
      await this.getItems(1, 5, "");
    },

    handleUpload(item) {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
      this.selectedId = item.id;
    },

    async onFileChanged(e) {
      this.loadingFileUpload = true;
      this.selectedFile = e.target.files[0];
      const allowedFileType = [
        "image/png",
        "image/jpeg",
        "image/bmp",
        "image/gif",
        "application/pdf",
      ];
      if (!allowedFileType.includes(this.selectedFile.type)) {
        this.loadingFileUpload = false;
        this.$store.dispatch(
          "snackbar/show",
          {
            text: "File type not supported",
            timeout: 10000,
          },
          {
            root: true,
          }
        );
        return;
      }
      await this.uploadDocument({
        file: this.selectedFile,
        id: this.selectedId,
      }).then((data) => {
        this.loadingFileUpload = false;
      });
      await this.getItems(1, 5, "");
    },

    async downloadQuotationDocument(item) {
      if (!item.document) {
        this.$store.dispatch(
          "snackbar/show",
          {
            text: "There is no uploaded document for this quotation.",
            timeout: 10000,
          },
          {
            root: true,
          }
        );
        return;
      }
      const redirect = window.open(item.document, "_blank");
      redirect.location;
    },
  },
};
</script>

<style></style>
